import React, { useEffect, useState } from 'react'
import { useFlag } from 'toggled'

import { useCurrencyConfig, useIsMobile } from '~/hooks'
import Icon from '~/truck/icon'
import Image from '~/truck/image'
import theme from '~/truck/theme'
import type { ILineItem } from '~/types/line-items'
import { cloudinary, formatPrice } from '~/utils'
import { flags, transformations } from '~/utils/constants'

import {
  IconContainer,
  ImageContainer,
  LabelsContainer,
  LineItemImageContainer,
  LineItemQuantityCircle,
  LineItemsList,
  LineItemsSummaryContainer,
  LineItemSummaryContainer,
  TitleContainer,
} from './LineitemsSummary.style'

interface LineItemsProps {
  name: string
  sku: string
  quantity: number
  total?: number
  photo: string
  presentation: string
  attributesSummary?: string
}
interface LineItemsSummaryProps {
  lineItems: ILineItem[]
}

function LineItem({ name, sku, quantity, total, photo, presentation, attributesSummary }: LineItemsProps) {
  const currencyConfig = useCurrencyConfig()

  const [valueInt, valueDecimal] = formatPrice(total, currencyConfig)

  return (
    <LineItemSummaryContainer>
      <LineItemImageContainer>
        <LineItemQuantityCircle>{quantity}</LineItemQuantityCircle>
        <ImageContainer>
          <Image src={photo} height={theme.spacing.compact * 13} width={theme.spacing.compact * 13} />
        </ImageContainer>
      </LineItemImageContainer>
      <div className="flex w-full items-center justify-between">
        <div className="flex-1">
          <p className="text-xs">{name}</p>
          <p className="text-xs text-tertiary">sku: {sku}</p>
          {presentation && <p className="text-xs text-tertiary">{presentation}</p>}
          {attributesSummary && <p className="text-xs text-tertiary">{attributesSummary}</p>}
        </div>
        {total && (
          <p className="text-secondary">
            {valueInt}
            {valueDecimal && (
              <>
                {currencyConfig.decimal}
                {valueDecimal}
              </>
            )}
          </p>
        )}
      </div>
    </LineItemSummaryContainer>
  )
}

export function LineItemsSummary(props: LineItemsSummaryProps) {
  const { lineItems } = props

  const [isOpen, setIsOpen] = useState(null)

  const subtitle = `${lineItems.length} ${lineItems.length > 1 ? 'productos' : 'producto'}`

  const hidePricing = useFlag(flags.HIDE_PRICES)

  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true)
    }
  }, [isMobile])

  return (
    <LineItemsSummaryContainer>
      <TitleContainer $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <LabelsContainer>
          <p className="font-semibold">Resumen de compra</p>
          <p className="text-xs text-secondary">{subtitle}</p>
        </LabelsContainer>
        <IconContainer>
          <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="sm" color="grayscale80" />
        </IconContainer>
      </TitleContainer>
      <LineItemsList $isOpen={isOpen}>
        {lineItems.map((lineItem, idx) => (
          <LineItem
            key={idx}
            name={lineItem.name}
            sku={lineItem.sku}
            quantity={lineItem.quantity}
            total={!hidePricing ? lineItem.price * lineItem.quantity : null}
            photo={cloudinary.applyTransformations(lineItem.photo, transformations.TINY_PRODUCT)}
            presentation={lineItem.presentation}
            attributesSummary={lineItem.product.attributesSummary}
          />
        ))}
      </LineItemsList>
    </LineItemsSummaryContainer>
  )
}
