import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { getTextStyle } from '~/truck/utils'

const sharedStyle = `
  border-radius: ${theme.spacing.cozy * 5}px;
  height: ${theme.spacing.cozy * 6}px;
`

export const TabContainer = styled.nav`
  background-color: ${theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary};
  display: flex;
  margin-bottom: ${theme.spacing.comfortable}px;
  margin-left: ${theme.spacing.comfortable}px;
  margin-right: ${theme.spacing.comfortable}px;
  position: relative;

  ${sharedStyle};

  ${up('lg')} {
    margin-bottom: ${theme.spacing.comfortable}px;
    margin-left: 0;
    margin-right: 0;
  }
`

const getColor = props => {
  const { active, theme: providedTheme } = props

  if (!active) {
    return providedTheme.colors.primary
  }

  return providedTheme.colors.white
}

const getCursor = props => {
  const { interactive } = props

  if (!interactive) {
    return 'auto'
  }

  return 'pointer'
}

export const TabItem = styled.span`
  align-items: center;
  color: ${getColor};
  cursor: ${getCursor};
  display: flex;
  flex: 1;
  justify-content: center;
  transition:
    fill 0.2s ease-in,
    color 0.2s ease-in;
  user-select: none;
  z-index: 1;

  ${getTextStyle('h5Regular')};
  ${sharedStyle};

  > svg {
    fill: ${getColor};
  }
`

export const getIndicatorStyle = props => {
  const { numOfTabs, activeIndex } = props
  const tabWidth = `calc(100% / ${numOfTabs})`
  const left = `calc(${activeIndex} * ${tabWidth})`

  return `
    width: ${tabWidth};
    left: ${left};
  `
}

export const Indicator = styled.span`
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${theme.spacing.cozy * 5}px;
  height: 100%;
  position: absolute;
  transition: left 0.17s ease-in-out;
  z-index: 0;

  ${getIndicatorStyle};
`
