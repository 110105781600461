import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const LineItemsSummaryContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact}px;
`

export const TitleContainer = styled.div<{ $isOpen: boolean }>`
  cursor: pointer;
  display: flex;
  padding: ${props => props.theme.spacing.comfortable}px;
  padding-bottom: ${props => (props.$isOpen ? 0 : props.theme.spacing.comfortable)}px;
  width: 100%;
`

export const LineItemSummaryContainer = styled.li`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.palette.grayscale40};
  display: flex;
  gap: ${props => props.theme.spacing.cozy}px;
  justify-content: flex-start;
  padding: ${props => props.theme.spacing.cozy}px 0;
`

export const LineItemsList = styled.ul<{ $isOpen: boolean }>`
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 ${props => props.theme.spacing.comfortable}px;
  position: relative;

  ${up('lg')} {
    max-height: ${props => props.theme.spacing.cozy * 25}px;
  }

  > :last-child {
    border-bottom: 0;
  }
`

export const LineItemImageContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: ${props => props.theme.spacing.cozy * 9}px;
  justify-content: flex-end;
  position: relative;
  width: ${props => props.theme.spacing.cozy * 12}px;
`

export const LineItemQuantityCircle = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.gray};
  border-radius: 1rem;
  display: flex;
  font-size: ${props => props.theme.spacing.compact * 3}px;
  font-weight: 600;
  height: 1.5rem;
  justify-content: center;
  padding: 0 0.5rem;
  width: fit-content;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const LabelsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${props => props.theme.spacing.compact}px;
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${props => props.theme.spacing.relaxed}px;
`
