import React from 'react'

import { Logo } from '~/components'
import useVendor from '~/hooks/use-vendor'
import Icon from '~/truck/icon'
import { forceReloadTo } from '~/utils'

import { Container, InnerContainer, SecureContainer } from './elements'

const Header = () => {
  const vendor = useVendor()

  return (
    <Container>
      <InnerContainer>
        <Logo
          onClick={event => {
            event.preventDefault()

            forceReloadTo('/')
          }}
          vendor={vendor}
          size="small"
        />
        <SecureContainer>
          <Icon type="lock" color="white" interactive={false} />
          <span className="text-xs font-semibold text-white">Pago Seguro</span>
        </SecureContainer>
      </InnerContainer>
    </Container>
  )
}

export default Header
