import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'

import { removeCouponMutation } from '~/gql/mutations'
import { orderCouponsQuery, priceLinesQuery } from '~/gql/queries'
import { IconButton } from '~/truck/icon-button'
import Label from '~/truck/label'
import notifier from '~/truck/notifier'
import type { ICoupon } from '~/types/coupons'
import { handleSubmissionErrors } from '~/utils'

import Action from '../action'

import { StyledAddCouponContainer, StyledCoupon, StyledCouponCard, StyledCouponList } from './CouponCard.style'

interface CouponCardProps {
  couponsList: ICoupon[]
  onOpen: (value: boolean) => void
  orderId: string
  style?: React.CSSProperties
}

export function CouponCard(props: CouponCardProps) {
  const { couponsList, onOpen, orderId, style } = props

  const [removeCoupon] = useMutation(removeCouponMutation)

  const onDelete = async (couponId: string) => {
    try {
      await removeCoupon({
        variables: { couponId },
        refetchQueries: [
          {
            query: orderCouponsQuery,
            variables: {
              orderId,
            },
          },
          {
            query: priceLinesQuery,
          },
        ],
      })

      notifier.success('El cupón ha sido eliminado')
    } catch (error) {
      handleSubmissionErrors(error)
    }
  }

  return (
    <StyledCouponCard $hasScroll={couponsList?.length > 2} style={style}>
      <StyledAddCouponContainer>
        <Label as="span" $textStyle="h5Semibold">
          Cupón
        </Label>
        <Action
          $color="info"
          onClick={() => {
            onOpen(true)
          }}
          style={{ marginLeft: 'auto' }}
        >
          Agregar
        </Action>
      </StyledAddCouponContainer>
      {!isEmpty(couponsList) && (
        <StyledCouponList>
          {couponsList.map((coupon: ICoupon, idx: number) => (
            <StyledCoupon key={idx}>
              <Label as="span" $textStyle="h5Regular" $color="grayscale60" $ellipsis>
                {coupon.code}
              </Label>
              <IconButton size="md" color="error" type="trash" onClick={() => onDelete(coupon.id)} />
            </StyledCoupon>
          ))}
        </StyledCouponList>
      )}
    </StyledCouponCard>
  )
}
