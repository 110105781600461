import React from 'react'
import { findIndex, map } from 'lodash'
import { useRouter } from 'next/router'

import { Indicator, TabContainer, TabItem } from './elements'

const Tabs = props => {
  const { dataSource } = props

  const router = useRouter()

  const currentStep = router.query.stepName

  const query = { step: currentStep }

  const activeIndex = findIndex(dataSource, query)

  const renderTab = (tab, index) => {
    const route = `/comprar/${tab.step}`

    const interactive = index <= activeIndex

    return (
      <TabItem
        key={`tab-${tab.step}`}
        interactive={interactive}
        active={tab.step === currentStep}
        onClick={() => interactive && router.push(route)}
      >
        {tab.label}
      </TabItem>
    )
  }

  return (
    <TabContainer>
      <Indicator activeIndex={activeIndex} numOfTabs={dataSource.length} />
      {map(dataSource, renderTab)}
    </TabContainer>
  )
}

export default Tabs
