import styled, { css } from 'styled-components'

export const StyledCouponCard = styled.div<{ $hasScroll: boolean }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact}px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props =>
    props.$hasScroll &&
    css`
      padding-bottom: ${props => props.theme.spacing.comfortable}px;
    `}
`

export const StyledAddCouponContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${props => props.theme.spacing.cozy * 7}px;
  padding: ${props => props.theme.spacing.cozy}px ${props => props.theme.spacing.comfortable}px;
`

export const StyledCouponList = styled.ul`
  max-height: ${props => props.theme.spacing.relaxed * 6}px;
  overflow: auto;
  padding: ${props => props.theme.spacing.compact}px 0;
`

export const StyledCoupon = styled.li`
  align-items: center;
  display: flex;
  height: ${props => props.theme.spacing.cozy * 7}px;
  justify-content: space-between;
  padding: ${props => props.theme.spacing.cozy}px ${props => props.theme.spacing.comfortable}px;
`
