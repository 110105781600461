import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import theme from '~/truck/theme'
import { headerHeights, stack } from '~/variables'

export const Container = styled.header`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  height: ${headerHeights.checkout}px;
  justify-content: center;
  left: 0;
  padding: 0 ${theme.spacing.comfortable}px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${stack.mainHeader};
`

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${up('lg')} {
    width: ${theme.spacing.cozy * 128}px;
  }
`

export const SecureContainer = styled.div`
  align-items: center;
  display: flex;

  > :first-child {
    margin-right: ${theme.spacing.cozy}px;
  }
`
