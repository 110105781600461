import styled from 'styled-components'

export const IconButtonContainer = styled.div<{ disabled?: boolean; size: string }>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: fit-content;

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}

  ${props =>
    props.size === 'sm' &&
    `
    padding: ${props.theme.spacing.compact}px;
  `}

  ${props =>
    props.size === 'md' &&
    `
    padding: ${props.theme.spacing.cozy}px;
  `}

  * {
    :hover {
      filter: brightness(0.89);
    }

    ${props =>
      props.disabled &&
      `
        fill: ${props.theme.palette.grayscale60};
        :hover {
          filter: none;
        }
        cursor: inherit;
      `}
  }
`
