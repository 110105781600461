import { filter, find, get, isNil, mapValues, omitBy, reject, some } from 'lodash'

import * as constants from '~/utils/constants'
import isValidTimeRange from '~/utils/is-valid-time-range'

const dataPaths = {
  deliveryAddressId: 'deliveryAddress.id',
  storeId: 'store.id',
  documentId: 'document.id',
  deliveryTimeRangeId: 'deliveryTimeRange.id',
  documentTypeSlug: 'document.documentType.slug',
  shippingTypeId: 'shippingType.id',
}

/**
 * Gets some ids and slugs from an order instance to preload the checkout state,
 * i.e. The FinalForm state inside the checkout.
 * Note that it can receive the user's last order, so it can't preload values
 * like dedicationMessage or deliveryDate.
 *
 * @param {Order} order Could be the last order or the current one
 * @returns {object} A clean checkout state, without null or undefined values
 */
const getCleanAssociations = order => {
  const getValue = dataPath => get(order, dataPath)

  const checkoutState = mapValues(dataPaths, getValue)

  return omitBy(checkoutState, isNil)
}

/**
 * Gets some current checkout values to re-set the final form state
 *
 * @param {Order} currentOrder The current order which is processing
 * @param {object} checkoutConfig Contains the default delivery date
 * @returns {object}
 */
const getCheckoutValues = (currentOrder, checkoutConfig) => {
  const checkoutValues = {
    dedicationMessage: currentOrder.dedicationMessage,
    hasDedicationMessage: !isNil(currentOrder.dedicationMessage),
    deliveryDate: currentOrder.deliveryDate,
    purchaseOrderUrl: currentOrder.purchaseOrderUrl,
    needsPurchaseOrder: currentOrder.needsPurchaseOrder,
  }

  const { paymentMethod } = currentOrder

  if (paymentMethod) {
    const isValid = some(checkoutConfig.paymentMethods, {
      id: paymentMethod.id,
    })

    if (isValid) {
      checkoutValues.paymentMethodId = paymentMethod.id

      checkoutValues.paymentMethodSlug = paymentMethod.slug
    }
  }

  return checkoutValues
}

/**
 * Check if the initial values has a valid document type
 *
 * @param {object} initialValues May contain the initial document type
 * @param {object} checkoutConfig Contains the vendor's document types
 */
const hasValidDocumentType = (initialValues, checkoutConfig) => {
  const { documentTypes } = checkoutConfig

  const { documentTypeSlug } = initialValues

  return some(documentTypes, { slug: documentTypeSlug })
}

/**
 * Check if the initial values has a valid shipping type
 *
 * @param {object} initialValues May contain the initial shipping type
 * @param {object} checkoutConfig Contains the vendor's shipping types
 */
const hasValidShippingType = (initialValues, checkoutConfig) => {
  const { shippingTypes } = checkoutConfig

  const { shippingTypeId } = initialValues

  return some(shippingTypes, { id: shippingTypeId })
}

/**
 * Check if the initial values has a valid delivery time range
 *
 * @param {object} initialValues May contain the initial delivery time range
 * @param {object} checkoutConfig Contains the vendor's delivery time ranges
 */
const hasValidDeliveryTimeRange = (initialValues, checkoutConfig) => {
  const { deliveryTimeRanges } = checkoutConfig

  const { deliveryTimeRangeId, deliveryDate } = initialValues

  const query = { id: deliveryTimeRangeId }

  const deliveryTimeRange = find(deliveryTimeRanges, query)

  if (!deliveryTimeRange) {
    return false
  }

  const preferCheckMinHour = checkoutConfig?.settings?.deliveryTimeRange?.preferCheckMinHour

  const validationOptions = {
    deliveryDate,
    preferCheckMinHour,
    timeRange: deliveryTimeRange.time,
  }

  return isValidTimeRange(validationOptions)
}

const validateValues = (baseValues, config) => {
  const values = {
    ...baseValues,
  }

  const {
    deliveryTimeRanges,
    documentTypes: [firstDocumentType],
    shippingTypes: [firstShippingType],
  } = config

  if (!hasValidDocumentType(values, config)) {
    values.documentTypeSlug = firstDocumentType.slug
  }

  if (!hasValidShippingType(values, config)) {
    values.shippingTypeId = firstShippingType.id
  }

  const query = {
    id: values.shippingTypeId,
  }

  const shippingTypeSlug = find(config.shippingTypes, query).slug

  const isPickup = shippingTypeSlug === constants.shippingTypes.PICKUP_IN_STORE

  if (isPickup) {
    values.deliveryAddressId = null

    if (config.buyingInStore) {
      values.storeId = config.buyingInStore.id
    }
  } else {
    values.storeId = null
  }

  if (!deliveryTimeRanges) {
    return values
  }

  const [firstDeliveryTimeRange] = deliveryTimeRanges

  if (deliveryTimeRanges.length === 1) {
    values.deliveryTimeRangeId = firstDeliveryTimeRange.id
  }

  if (!hasValidDeliveryTimeRange(values, config)) {
    delete values.deliveryTimeRangeId
  }

  return values
}

/**
 * Check if store properties should filter shippingTypes
 *
 * @param {object} checkoutConfig Contains the vendor's shipping types
 * @param {object} isExclusiveDelivery If the current store has makesDelivery = true
 */
const getShippingTypes = (checkoutConfig, isExclusiveDelivery) => {
  const query = ['slug', constants.shippingTypes.DELIVERY]

  if (isExclusiveDelivery) {
    return [find(checkoutConfig.shippingTypes, query)]
  }

  return reject(checkoutConfig.shippingTypes, query)
}

const getPaymentMethods = checkoutConfig => {
  let { paymentMethods } = checkoutConfig

  if (checkoutConfig.settings) {
    const paymentMethodsSlugs = checkoutConfig.settings.deliveryPaymentMethods

    if (Array.isArray(paymentMethodsSlugs)) {
      const getDeliveryPaymentMethods = paymentMethod => paymentMethodsSlugs.includes(paymentMethod.slug)

      paymentMethods = filter(checkoutConfig.paymentMethods, getDeliveryPaymentMethods)
    }
  }

  return paymentMethods
}

/**
 * Gets the initial values of checkout's final form instance. It takes care of
 * the last order to re-set the last used values in the checkout process
 */
const getInitialValues = config => {
  const { checkoutData, buyingInStore, stores, multiDistributorSession, expressDeliverySession, comments } = config

  const {
    checkoutConfig,
    checkoutState: { lastOrder, currentOrder, addresses },
  } = checkoutData

  const hasStoreExclusiveDelivery = some(stores, 'makesDelivery')

  const isExclusiveDelivery = buyingInStore?.makesDelivery

  if (hasStoreExclusiveDelivery && !multiDistributorSession && !expressDeliverySession) {
    checkoutConfig.shippingTypes = getShippingTypes(checkoutConfig, isExclusiveDelivery)
  }

  if (isExclusiveDelivery) {
    checkoutConfig.paymentMethods = getPaymentMethods(checkoutConfig)
  }

  const lastOrderAssociations = getCleanAssociations(lastOrder)

  const currentAssociations = getCleanAssociations(currentOrder)

  const currentCheckoutValues = getCheckoutValues(currentOrder, checkoutConfig)

  const baseValues = {
    ...lastOrderAssociations,
    ...currentAssociations,
    ...currentCheckoutValues,
    comments,
  }

  const initialValues = validateValues(baseValues, {
    ...checkoutConfig,
    addresses,
    buyingInStore,
  })

  if (multiDistributorSession) {
    const shippingType = find(checkoutConfig.shippingTypes, {
      isDelivery: multiDistributorSession.shippingType.isDelivery,
      isPickup: multiDistributorSession.shippingType.isPickup,
    })

    initialValues.shippingTypeId = shippingType.id

    const { deliveryAddress, storeId } = multiDistributorSession

    if (deliveryAddress) {
      initialValues.deliveryAddressId = deliveryAddress.id
    } else if (storeId) {
      initialValues.storeId = storeId
    }
  }

  if (expressDeliverySession) {
    initialValues.shippingTypeId = expressDeliverySession.shippingType.id

    checkoutConfig.stores = stores

    const { deliveryAddress, storeId } = expressDeliverySession

    if (deliveryAddress) {
      initialValues.deliveryAddressId = deliveryAddress.id
    } else if (storeId) {
      initialValues.storeId = storeId
    }
  }

  return initialValues
}

export default getInitialValues
