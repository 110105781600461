import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

// TODO: remove when styled-components has an official support for Hooks
// https://github.com/styled-components/styled-components/issues/2340

const useTheme = () => {
  return useContext(ThemeContext)
}

export default useTheme
