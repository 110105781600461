import React from 'react'
import { useFlag } from 'toggled'

import type { PriceLineProps } from '~/components/pricing/price-line'
import { CartPricingMobile } from '~/modules/checkout/components/cart-pricing-mobile/CartPricingMobile'
import Anchor from '~/truck/anchor'
import Button from '~/truck/button'
import { flags } from '~/utils/constants'

import { NavigatorContainer } from './elements'

interface NavigatorProps {
  mainButtonLabel: string
  isMainButtonDisabled: boolean
  isMainButtonLoading: boolean
  onClickMainButton: () => void
  showCartPricing: boolean
  isCartPricingOpen: boolean
  onCloseCartPricing: () => void
  priceLines?: PriceLineProps[]
}

const Navigator = (props: NavigatorProps) => {
  const {
    mainButtonLabel,
    isMainButtonDisabled,
    isMainButtonLoading,
    onClickMainButton,
    showCartPricing,
    isCartPricingOpen,
    onCloseCartPricing,
    priceLines,
  } = props

  const attachPaymentSummary = useFlag(flags.DEV_ATTACH_PAYMENT_SUMMARY)

  return (
    <NavigatorContainer $showPartialCartPricing={!isCartPricingOpen && attachPaymentSummary}>
      {showCartPricing && priceLines && (
        <CartPricingMobile lines={priceLines} onClose={onCloseCartPricing} isOpen={isCartPricingOpen} />
      )}
      <Button
        color="secondary"
        disabled={isMainButtonDisabled}
        onClick={onClickMainButton}
        loading={isMainButtonLoading}
      >
        {mainButtonLabel}
      </Button>
      <Anchor href="/" $color="info" $textStyle="h5Semibold">
        Seguir comprando
      </Anchor>
    </NavigatorContainer>
  )
}

export default Navigator
