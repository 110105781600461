import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export const CartPricingContainer = styled.div<{ $showCartPricing: boolean }>`
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact}px;
  display: ${props => (props.$showCartPricing ? 'block' : 'none')};

  ${up('lg')} {
    display: block;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing.comfortable}px;
  padding-bottom: 0;
  width: 100%;
`

export const LabelsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${props => props.theme.spacing.compact}px;
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: ${props => props.theme.spacing.relaxed}px;
`

export const PriceLineList = styled.div`
  border-radius: ${props => props.theme.spacing.compact}px;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.cozy}px;
  padding: ${props => props.theme.spacing.comfortable}px;
`

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const StyledSeparator = styled.div`
  background: ${props => props.theme.colors.gray};
  height: 1px;
  margin-bottom: ${props => props.theme.spacing.cozy}px;
  margin-top: ${props => props.theme.spacing.cozy}px;
`
