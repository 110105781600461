import React from 'react'
import { filter, map, reject } from 'lodash'

import Price from '~/components/price'
import { type PriceLineProps, PriceLineType } from '~/components/pricing/price-line'
import Icon from '~/truck/icon'
import Label from '~/truck/label'

import {
  CartPricingContainer,
  DetailsContainer,
  PriceLineList,
  StyledPriceLine,
  StyledSeparator,
  StyledTotalPriceLine,
  TitleContainer,
  TotalContainer,
} from './CartPricingMobile.style'

interface CartPricingMobile {
  lines: PriceLineProps[]
  onClose: () => void
  isOpen: boolean
}

export function CartPricingMobile(props: CartPricingMobile) {
  const { lines, onClose, isOpen } = props

  const linesWithoutTotal = reject(lines, { label: 'Total' })

  const total = filter(lines, { label: 'Total' })

  return (
    <CartPricingContainer>
      {isOpen && (
        <DetailsContainer>
          <TitleContainer>
            <p className="text-xl font-semibold">Precio detallado</p>
            <Icon type="close" color="currentColor" size="sm" onClick={onClose} />
          </TitleContainer>
          <PriceLineList>
            {map(isOpen ? linesWithoutTotal : total, lineitem => {
              if (lineitem.type === PriceLineType.LABEL) {
                return (
                  <StyledPriceLine>
                    <span className="text-xs text-secondary">{lineitem.label}</span>
                  </StyledPriceLine>
                )
              }

              if (lineitem.type === PriceLineType.SEPARATOR) {
                return <StyledSeparator $showSeparator={isOpen} />
              }

              return (
                <StyledPriceLine>
                  <Label as="span" $textStyle={lineitem.lineStyle?.textStyle}>
                    {lineitem.label}
                  </Label>
                  <Price
                    value={lineitem?.price}
                    textStyle={lineitem?.lineStyle?.textStyle}
                    color={lineitem?.lineStyle?.priceColor}
                  />
                </StyledPriceLine>
              )
            })}
          </PriceLineList>
        </DetailsContainer>
      )}
      <TotalContainer onClick={onClose}>
        <StyledPriceLine onClick={onClose}>
          <StyledTotalPriceLine>
            <span className="font-semibold">Total</span>
            <Icon type={props.isOpen ? 'caret-down' : 'caret-up'} color="black" size="sm" />
          </StyledTotalPriceLine>
          {/* @ts-ignore */}
          <Price value={total[0].price} $textStyle="h5Semibold" color="black" />
        </StyledPriceLine>
      </TotalContainer>
    </CartPricingContainer>
  )
}
