import React, { useContext, useEffect } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/router'
import { useFlag } from 'toggled'

import { FormScrollToError } from '~/components/form-scroll-to-error'
import ShippingSelectorModal from '~/components/shipping-modal'
import { CheckoutContext } from '~/contexts'
import { commentsQuery, storesQuery } from '~/gql/queries'
import useBuyingInStore from '~/hooks/use-buying-in-store'
import useExpressDeliverySession from '~/hooks/use-express-delivery-session'
import useModalManager from '~/hooks/use-modal-manager'
import useMultiDistributorSession from '~/hooks/use-multi-distributor-session'
import notifier from '~/truck/notifier'
import { flags, sessionStorageKeys } from '~/utils/constants'

import CheckoutContent from './content'
import getInitialValues from './get-initial-values'
import CheckoutHeader from './header'

const CheckoutLayout = props => {
  const { children, processing, onSubmit, onValidate } = props

  const buyingInStore = useBuyingInStore()

  const { multiDistributorSession } = useMultiDistributorSession()

  const { expressDeliverySession } = useExpressDeliverySession()

  const router = useRouter()

  const storesResponse = useQuery(storesQuery, {
    variables: { showAll: false },
  })

  const checkoutData = useContext(CheckoutContext)

  const {
    checkoutState: { currentOrder },
  } = checkoutData

  const { data: commentsData } = useQuery(commentsQuery, { fetchPolicy: 'cache-only' })

  const comments = commentsData?.user?.cart?.comments ?? currentOrder.comments

  const { openModal } = useModalManager()

  const hasShippingBeforeBuyFF = useFlag(flags.SHIPPING_BEFORE_BUY)

  const hasExpressDeliveryFF = useFlag(flags.EXPRESS_DELIVERY)

  const sessionNotFound =
    (hasExpressDeliveryFF && !expressDeliverySession) || (!hasExpressDeliveryFF && !multiDistributorSession)

  const suggestedProductsModalFF = useFlag(flags.SUGGESTED_PRODUCTS_MODAL)

  useEffect(() => {
    const deliveryAddress = expressDeliverySession?.deliveryAddress ?? multiDistributorSession?.deliveryAddress

    const store = expressDeliverySession?.store ?? multiDistributorSession?.store

    if (suggestedProductsModalFF) {
      sessionStorage.removeItem(sessionStorageKeys.SUGGESTED_PRODUCT_MODAL_DISABLE)
    }

    if (hasShippingBeforeBuyFF && (sessionNotFound || (!deliveryAddress && !store))) {
      notifier.error('Necesitas escoger un método de envío y dirección antes de procesar la compra.')

      openModal(ShippingSelectorModal)

      router.replace('/')
    }
  }, [expressDeliverySession, hasShippingBeforeBuyFF])

  if (storesResponse.loading) {
    return null
  }

  const stores = storesResponse.data?.vendor?.stores?.items

  const initialValues = getInitialValues({
    checkoutData,
    buyingInStore,
    stores,
    multiDistributorSession,
    expressDeliverySession,
    comments,
  })

  return (
    <FinalForm initialValues={initialValues} onSubmit={onSubmit} validate={onValidate}>
      {formProps => {
        return (
          <>
            <FormScrollToError addHeaderOffset={true} />
            <CheckoutContent
              processing={processing}
              checkoutData={checkoutData}
              formProps={formProps}
              orderId={currentOrder.id}
            >
              {children}
            </CheckoutContent>
          </>
        )
      }}
    </FinalForm>
  )
}

CheckoutLayout.Header = CheckoutHeader

export default CheckoutLayout
