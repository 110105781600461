import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import Card from '~/truck/card'
import theme from '~/truck/theme'

export const CartPricingContainer = styled.div`
  width: 100%;

  ${up('lg')} {
    display: none;
  }
`

export const DetailsContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  padding-bottom: ${theme.spacing.compact}px;
  width: 100%;
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${theme.spacing.cozy * 5}px;
  justify-content: space-between;
  padding-bottom: ${theme.spacing.comfortable}px;
`

export const PriceLineList = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.cozy}px;
  padding-bottom: ${props => props.theme.spacing.cozy}px;
`

export const StyledPriceLine = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const StyledSeparator = styled.div<{ $showSeparator: boolean }>`
  background: ${props => props.theme.colors.gray};
  display: none;
  height: 1px;
  margin-bottom: ${props => props.theme.spacing.cozy}px;
  margin-top: ${props => props.theme.spacing.cozy}px;

  ${props =>
    props.$showSeparator &&
    css`
      display: block;
    `}
`

export const TotalContainer = styled.div`
  cursor: pointer;
  height: ${theme.spacing.cozy * 7}px;
  padding: ${theme.spacing.comfortable}px 0;
  width: 100%;
`

export const StyledTotalPriceLine = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: ${props => props.theme.spacing.compact * 15}px;
`
