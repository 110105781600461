import * as React from 'react'

import Icon from '~/truck/icon'
import type { ThemeColor } from '~/types/theme'

import { IconButtonContainer } from './elements'

export type IconButtonSize = 'sm' | 'md'

export interface IconButtonProps {
  onClick: (event: any) => void
  type: string
  size: IconButtonSize
  color: ThemeColor
  disabled?: boolean
}

export function IconButton(props: IconButtonProps) {
  const { size, disabled } = props

  return (
    <IconButtonContainer size={size} disabled={disabled}>
      <Icon {...props} />
    </IconButtonContainer>
  )
}
