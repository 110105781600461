import React from 'react'
import { map } from 'lodash'
import { useFlag } from 'toggled'

import Price from '~/components/price'
import { type PriceLineProps, PriceLineType } from '~/components/pricing/price-line'
import Label from '~/truck/label'
import { flags } from '~/utils/constants'

import {
  CartPricingContainer,
  LabelsContainer,
  PriceLineList,
  StyledContainer,
  StyledSeparator,
  TitleContainer,
} from './CartPricing.style'

interface CartPricingProps {
  lines: PriceLineProps[]
}

export function CartPricing(props: CartPricingProps) {
  const { lines } = props

  const hasAttachPaymentSummaryFF = useFlag(flags.DEV_ATTACH_PAYMENT_SUMMARY)

  return (
    <CartPricingContainer $showCartPricing={!hasAttachPaymentSummaryFF}>
      <TitleContainer>
        <LabelsContainer>
          <h5 className="font-semibold">Precio detallado</h5>
        </LabelsContainer>
      </TitleContainer>
      <PriceLineList>
        {map(lines, (line, index) => {
          if (line.type === PriceLineType.LABEL) {
            return (
              <StyledContainer key={`price-line-${index}`}>
                <span className="text-xs text-secondary">{line.label}</span>
              </StyledContainer>
            )
          }

          if (line.type === PriceLineType.SEPARATOR) {
            return <StyledSeparator key={`price-line-${index}`} />
          }

          return (
            <StyledContainer key={`price-line-${index}`}>
              <Label as="span" $textStyle={line.lineStyle?.textStyle}>
                {line.label}
              </Label>
              <Price
                className="shrink-0"
                value={line?.price}
                textStyle={line?.lineStyle?.textStyle}
                color={line?.lineStyle?.priceColor}
              />
            </StyledContainer>
          )
        })}
      </PriceLineList>
    </CartPricingContainer>
  )
}
