import React, { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { find, isEmpty, isFunction, last } from 'lodash'
import { withRouter } from 'next/router'
import styled from 'styled-components'
import { useFlag } from 'toggled'

import { TextareaWithCounter } from '~/components'
import Action from '~/components/action'
import { CouponCard } from '~/components/coupon-card'
import { CouponModal } from '~/components/coupon-modal/CouponModal'
import { orderCouponsQuery, ordersToBePaidQuery, priceLinesQuery } from '~/gql/queries'
import { useIsMobile, useLineOfCredit, useVendor } from '~/hooks'
import useCheckoutTabs from '~/hooks/use-checkout-tabs'
import { usePricing } from '~/hooks/use-pricing'
import { CartPricing } from '~/modules/checkout/components/cart-pricing/CartPricing'
import { LineItemsSummary } from '~/modules/checkout/components/line-items-summary/LineItemsSummary'
import Anchor from '~/truck/anchor'
import Card from '~/truck/card'
import Field from '~/truck/field'
import { useTheme } from '~/truck/hooks'
import Overlay from '~/truck/overlay'
import { getPaymentMethodMinimumAmount } from '~/utils'
import { checkoutSteps, flags, orderStatuses, paymentMethods } from '~/utils/constants'

import Header from '../header'
import Navigator from '../navigator'
import Tabs from '../tabs'

import {
  CheckoutContainer,
  CommentsContainer,
  CommentsInputContainer,
  CommentsLabelContainer,
  PanelContainer,
  PricingContainer,
  StepContainer,
  TabContent,
  TabsContainer,
  TermsAndConditionsSectionContainer,
} from './elements'

const StyledOverlay = styled(Overlay)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const getChildren = props => {
  const { children, checkoutData, formProps, ordersToBePaid, paymentMethodCurrentData } = props

  if (!isFunction(children)) {
    return children
  }

  return children({
    checkoutData,
    formProps,
    ordersToBePaid,
    paymentMethodCurrentData,
  })
}

const TermsAndConditionsSection = props => {
  const { requireTermsAndConditions, paymentMethodSlug, vendor, requirePrivacyPolicy, style } = props

  const theme = useTheme()

  return (
    <TermsAndConditionsSectionContainer style={style}>
      {requireTermsAndConditions && (
        <Card className="p-4">
          <Field
            as="checkbox"
            name="termsAndConditionsAccepted"
            hasText
            style={{ marginBottom: requirePrivacyPolicy ? theme.spacing.comfortable : 0 }}
            textStyle="h6Regular"
          >
            {'He leído y acepto los '}
            <Anchor $textStyle="h6Regular" href={`/terminos-y-condiciones/${paymentMethodSlug ?? ''}`} target="_blank">
              Términos y Condiciones
            </Anchor>
            {` de ${vendor.name}`}
          </Field>
        </Card>
      )}
      {requirePrivacyPolicy && (
        <Card className="p-4">
          <Field as="checkbox" name="privacyPolicyAccepted" hasText textStyle="h6Regular">
            {'He leído y acepto la '}
            <Anchor $textStyle="h6Regular" href="/politica-de-privacidad" target="_blank">
              Política de Privacidad
            </Anchor>
            {` de ${vendor.name}`}
          </Field>
        </Card>
      )}
    </TermsAndConditionsSectionContainer>
  )
}

const CheckoutContent = props => {
  const {
    router,
    processing,
    checkoutData,
    formProps: {
      handleSubmit: onClickMainButton,
      submitting: submittingMainButton,
      values: { paymentMethodSlug, termsAndConditionsAccepted, privacyPolicyAccepted, comments },
    },
    orderId,
  } = props

  const vendor = useVendor()

  const { privacyPolicyUrl, legalTermsUrl } = vendor

  const {
    checkoutConfig,
    checkoutState: { currentOrder },
  } = checkoutData

  const blockOrderByStatus = currentOrder.status?.slug === orderStatuses.TO_BE_PAID

  const query = {
    slug: paymentMethodSlug,
  }

  const [getOrdersToBePaid, ordersToBePaidResponse] = useLazyQuery(ordersToBePaidQuery)

  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false)

  const [isCartPricingOpen, setIsCartPricingOpen] = useState(false)

  const [isCommentsSectionOpen, setIsCommentsSectionOpen] = useState(false)

  const isMobile = useIsMobile()

  const onCloseCartPricing = () => {
    setIsCartPricingOpen(!isCartPricingOpen)
  }

  const { data: priceLinesData } = useQuery(priceLinesQuery, { fetchPolicy: 'cache-only' })

  const priceLines = priceLinesData?.user?.cart?.priceLines ?? currentOrder.priceLines

  const [getLineOfCredit, lineOfCreditData] = useLineOfCredit({
    orderPriceLineTotal: last(priceLines).value,
    fetchPolicy: 'network-only',
  })

  const { lines } = usePricing({
    orderPriceLines: priceLines,
    settings: {
      showTheBestRewardDescription: true,
    },
  })

  const commentsField = useField('comments')

  const paymentMethod = find(checkoutConfig.paymentMethods, query)

  const tabs = useCheckoutTabs(checkoutData)

  const isPaymentScreen = router.query.stepName === checkoutSteps.PAYMENT

  const hasOrderCommentsFF = useFlag(flags.ORDER_COMMENTS)

  const ordersToBePaid = ordersToBePaidResponse.data?.user?.ordersToBePaid

  const hasOrdersToBePaid = isPaymentScreen && !isEmpty(ordersToBePaid)

  /**
   * Current payment method data.
   */
  const paymentMethodCurrentData = React.useMemo(() => {
    const paymentCurrentData = {
      [paymentMethods.LINE_OF_CREDIT]: lineOfCreditData,
    }

    return paymentCurrentData[paymentMethod?.slug]
  }, [paymentMethod?.slug, lineOfCreditData?.isLoading])

  /**
   * Listener of the current payment method.
   */
  useEffect(() => {
    if (!isPaymentScreen || !paymentMethod) return

    if (paymentMethod.slug === paymentMethods.LINE_OF_CREDIT) {
      getLineOfCredit()
    }

    getOrdersToBePaid({
      variables: {
        paymentMethodId: paymentMethod.id,
      },
    })
  }, [paymentMethod?.slug])

  const getNavigatorLabel = () => {
    if (!isPaymentScreen) {
      return 'Siguiente'
    }

    if (paymentMethod && (paymentMethod.isCard || paymentMethod.isExternal)) {
      return 'Pagar y Finalizar'
    }

    return 'Finalizar'
  }

  const needsToAgreeToLegalDocumentsOnCheckoutSteps = useFlag(flags.NEEDS_TO_AGREE_TO_LEGAL_DOCUMENTS_IN_CHECKOUT_STEPS)

  const requireTermsAndConditions =
    (!!legalTermsUrl && needsToAgreeToLegalDocumentsOnCheckoutSteps) ||
    (isPaymentScreen && paymentMethod?.settings?.requireTermsAndConditions)

  const requirePrivacyPolicy = !!privacyPolicyUrl && needsToAgreeToLegalDocumentsOnCheckoutSteps

  const paymentMethodMinimum = getPaymentMethodMinimumAmount(paymentMethod)

  const total = find(priceLines, { label: 'Total' })?.value

  const hasMinimumPaymentMethodValue = total >= paymentMethodMinimum

  const { data: orderData } = useQuery(orderCouponsQuery, {
    variables: {
      orderId: orderId,
    },
  })

  const hidePricing = useFlag(flags.HIDE_PRICES)

  const attachPaymentSummary = useFlag(flags.DEV_ATTACH_PAYMENT_SUMMARY)

  const shouldShowCommentsInput = comments || isCommentsSectionOpen

  const termsAndConditionsDisabled =
    (requireTermsAndConditions && !termsAndConditionsAccepted) || (requirePrivacyPolicy && !privacyPolicyAccepted)

  const paymentMethodNotSelected =
    !hasMinimumPaymentMethodValue || paymentMethodCurrentData?.isDisabled || hasOrdersToBePaid

  const isMainButtonDisabled = paymentMethodNotSelected || (isPaymentScreen && termsAndConditionsDisabled)

  return (
    <>
      <CheckoutContainer>
        <Header />
        <StyledOverlay open={isCouponModalOpen} blocking={false}>
          {isCouponModalOpen && (
            <CouponModal
              onClose={() => {
                setIsCouponModalOpen(false)
              }}
              orderId={orderId}
            />
          )}
        </StyledOverlay>
        <StepContainer>
          <TabsContainer>
            <Tabs dataSource={tabs} router={router} />
            <TabContent>
              {getChildren({
                ...props,
                ordersToBePaid,
                paymentMethodCurrentData,
              })}
            </TabContent>
          </TabsContainer>
          <PricingContainer requireTerms={requireTermsAndConditions}>
            <PanelContainer>
              <LineItemsSummary lineItems={currentOrder.lineItems} />
              {hidePricing ? (
                <Card className="p-4">
                  <span>Los precios han sido previamente acordados.</span>
                </Card>
              ) : (
                <CartPricing lines={lines} />
              )}
              <CouponCard
                couponsList={orderData?.order?.coupons}
                onOpen={() => setIsCouponModalOpen(true)}
                orderId={orderId}
                style={{ order: isMobile ? 1 : null }}
              />
              {hasOrderCommentsFF && (
                <CommentsContainer style={{ order: isMobile ? 2 : null }}>
                  <CommentsLabelContainer>
                    <h5 className="font-semibold">Comentario</h5>
                    {!isCommentsSectionOpen && !comments && (
                      <Action
                        $color="info"
                        onClick={() => {
                          setIsCommentsSectionOpen(true)
                        }}
                      >
                        Agregar
                      </Action>
                    )}
                  </CommentsLabelContainer>
                  {shouldShowCommentsInput && (
                    <CommentsInputContainer $isInsideCard>
                      <TextareaWithCounter {...commentsField.input} maxLength={200} />
                    </CommentsInputContainer>
                  )}
                </CommentsContainer>
              )}
              {isPaymentScreen && (requireTermsAndConditions || requirePrivacyPolicy) && (
                <TermsAndConditionsSection
                  style={{ flexDirection: 'column', order: 5 }}
                  paymentMethodSlug={paymentMethodSlug}
                  vendor={vendor}
                  requireTermsAndConditions={requireTermsAndConditions}
                  requirePrivacyPolicy={requirePrivacyPolicy}
                />
              )}
            </PanelContainer>
            <Navigator
              mainButtonLabel={getNavigatorLabel()}
              isMainButtonDisabled={isMainButtonDisabled}
              isMainButtonLoading={submittingMainButton || processing || blockOrderByStatus}
              onClickMainButton={onClickMainButton}
              showCartPricing={!hidePricing && attachPaymentSummary}
              isCartPricingOpen={isCartPricingOpen}
              onCloseCartPricing={onCloseCartPricing}
            />
          </PricingContainer>
        </StepContainer>
        <Navigator
          mainButtonLabel={getNavigatorLabel()}
          isMainButtonDisabled={isMainButtonDisabled}
          isMainButtonLoading={submittingMainButton || processing || blockOrderByStatus}
          onClickMainButton={onClickMainButton}
          showCartPricing={!hidePricing && attachPaymentSummary}
          isCartPricingOpen={isCartPricingOpen}
          onCloseCartPricing={onCloseCartPricing}
          priceLines={lines}
        />
      </CheckoutContainer>
    </>
  )
}

export default withRouter(CheckoutContent)
