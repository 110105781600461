import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import theme from '~/truck/theme'
import { stack } from '~/variables'

export const NavigatorContainer = styled.div<{
  $showPartialCartPricing: boolean
}>`
  align-items: center;
  background-color: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray};
  bottom: 0;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.cozy}px;
  left: 0;
  padding: ${theme.spacing.comfortable}px;
  position: sticky;
  z-index: ${stack.checkoutFooter};

  ${up('lg')} {
    background: transparent;
    border: none;
    bottom: unset;
    box-shadow: unset;
    display: flex;
    left: unset;
    padding: ${theme.spacing.comfortable}px 0;
    position: unset;
    right: unset;
  }

  ${props =>
    props.$showPartialCartPricing &&
    css`
      padding-top: 0;
    `}
`
