import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import theme from '~/truck/theme'

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: ${theme.spacing.comfortable * 4}px;
  position: relative;

  ${up('lg')} {
    flex-direction: row;
    justify-content: center;
    padding-top: 0;

    & > :last-child {
      display: none;
    }
  }
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.comfortable}px;
  position: relative;

  ${up('lg')} {
    flex-direction: row;
    padding-top: ${theme.spacing.comfortable * 4}px;
  }
`

export const TabsContainer = styled.div`
  width: inherit;

  ${up('lg')} {
    width: ${theme.spacing.cozy * 86}px;
  }
`

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.comfortable}px;
  justify-content: flex-start;
  padding-bottom: ${theme.spacing.comfortable}px;

  ${up('lg')} {
    padding-bottom: 0;
  }
`

export const PricingContainer = styled.div`
  flex-direction: column;
  width: inherit;

  // This style was added to hide navigator component in mobile view
  & > :last-child {
    display: none;
  }

  ${up('lg')} {
    width: ${theme.spacing.cozy * 40}px;

    & > :last-child {
      display: flex;
    }
  }
`

export const CommentsContainer = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.spacing.compact}px;
  padding: ${theme.spacing.comfortable}px;
`

export const CommentsLabelContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const CommentsInputContainer = styled.div<{ $isInsideCard: boolean }>`
  padding: ${theme.spacing.comfortable}px;

  textarea {
    resize: none;
  }

  ${props =>
    props.$isInsideCard &&
    css`
      padding: 0;
      padding-top: ${theme.spacing.comfortable}px;
    `}
`

export const TabContent = styled.section`
  background: ${theme.colors.white};
  border-radius: ${theme.dimensions.borderRadius}px;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.comfortable}px 0;
`

export const TermsAndConditionsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.comfortable}px;
`
